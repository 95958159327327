<template>
    <div class="row mb-3">
        <div >
            <div class="main-card card">
                <div class="card-header text-left text-unset">
                    {{$t('withdraw-title', ['BTC'])}}
                </div>
                <div class="card-body">
                    <div class="card-text fsize-1 mb-3">
                        {{$t('withdraw-btc-note')}}
                    </div>
                    <div class="spacer" />
                    <div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text pe-7s-wallet" style="line-height: 1.5"></span>
                            </div>
                            <input :placeholder="$t('withdraw-address', ['BTC'])" type="text" class="form-control" v-model="address" />
                        </div>
                        <br />
                        <div class="input-group">
                            <input :placeholder="$t('withdraw-amount')" step="0.01" type="number" class="form-control" v-model="amount" />
                            <div class="input-group-append">
                                <span class="input-group-text">HTL</span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-1">
                        <v-btn color="primary" class="ma-0 text-unset" @click="requestWithdrawal" :disabled="!kyc" small>
                            {{$t('withdraw-request')}}
                        </v-btn>
                        <br>
                        <span class="red--text" v-if="!kyc">{{$t('withdraw-no-kyc')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar
            v-model="success"
            :timeout="timeout"
            color="primary"
            absolute
            right
            rounded="pill"
        >
            {{$t('withdraw-requested', [lastAmount, 'HTL'])}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="success = false"
                    class="text-unset"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import gql from "graphql-tag";

export default {
    props: [
        "limit",
        "kyc"
    ],
    data() {
        return {
            address: null,
            success: false,
            amount: 0,
            timeout: 2000,
            lastAmount: 0
        }
    },
    computed: {
            ...mapGetters({
                token: 'prices/usdRate',
                htlBtc: 'prices/htlBtc',
                htlEur: 'prices/htlEur',
                htlUsd: 'prices/htlUsd',
                htlLtc: 'prices/htlLtc'
            })
        },
    mounted() {
        this.amount = Math.floor(this.limit * 100) / 100
    },
    methods: {
        async requestWithdrawal() {
            await this.$apollo.mutate({
                mutation: gql`mutation($address:String!,$amount:Float!, $rate:Float){requestWithdrawal(amount:$amount,address:$address,currency:"BTC", currencyFrom:"USDT", rate:$rate)}`,
                variables: {
                    amount: parseFloat(this.amount),
                    address: this.address,
                    rate: this.htlUsd
                },
            }).then(() => {
                this.$router.go(0)
            })
        }
    }
}

</script>