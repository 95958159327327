<template>
  <div class="mb-3" style="width:100%">
    <div  style="width:100%">
      <div class="main-card card">
        <div class="card-header text-left text-unset">
          {{ $t("withdraw-title", ["HTL"]) }}
        </div>
        <div class="card-body">
          <div class="card-text fsize-1 mb-3">
            {{ $t("withdraw-htl-note") }}
          </div>
          <div class="spacer" />
          <div>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text pe-7s-wallet" style="line-height: 1.5"></span>
              </div>
              <input :placeholder="$t('withdraw-address', ['HTL'])" type="text" class="form-control" v-model="address" />
            </div>
            <br />
            <div class="input-group">
              <input :placeholder="$t('withdraw-amount')" step="0.01" type="number" class="form-control" v-model="amount"
                :max="limit" />
              <div class="input-group-append">
                <span class="input-group-text">HTL</span>
              </div>
            </div>

            <div class="mt-2">
              <v-btn color="primary" class="text-unset mx-0" @click="requestWithdrawal" :disabled="!kyc" small>
                {{ $t("withdraw-request") }}
              </v-btn>
              <br />
              <span class="red--text" v-if="!kyc">{{
                $t("withdraw-no-kyc")
              }}</span>
              <span class="red--text" v-if="missingAddress===true">Missing HTL address!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="success" :timeout="timeout" color="primary" absolute right rounded="pill">
      {{ $t("withdraw-requested", [lastAmount, "HTL"]) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="success = false" class="text-unset">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["limit", "kyc"],
  data() {
    return {
      address: null,
      success: false,
      amount: 0,
      timeout: 2000,
      lastAmount: 0,
      missingAddress:false
    };
  },
  mounted() {
    this.amount = Math.floor(this.limit * 100) / 100;
  },
  methods: {
    async requestWithdrawal() {

      if(this.address===null)
      {
        this.missingAddress = true;
        return;
      }
      else{
        this.missingAddress = false;
      }


      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($address: String!, $amount: Float!) {
              requestWithdrawal(
                amount: $amount
                address: $address
                currency: "HTL"
                currencyFrom:"HTL"
              )
            }
          `,
          variables: {
            amount: parseFloat(this.amount),
            address: this.address,
            rate: ''
          },
        })
        .then(() => {
          this.lastAmount = this.amount;
          this.amount = 0;
          this.$emit("update");
        });
    },
  },
};
</script>