<template>
    <div class="row ">
        <div class="col-6 col-md-6 col-xl-3 mb-3" v-if="!depositAddress">
            <div class="card-shadow-primary widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  htl-border card">
                <div class="widget-chat-wrapper-outer d-block">
                    <div class="box-image overflow opacity-3">
                        <img class="rounded-circle image-right card-image" :src="require('@/assets/images/crypto/HTL.png')" alt="HTL" />
                    </div>
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading ">1 HTL =</h6>
                        <div class="widget-numbers w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" >{{htlEur.toFixed(4)}} EUR</div>
                            </div>
                        </div>
                        <div class="widget-subheading fsize-1 opacity-10 text-warning font-weight-bold">
                            <dialog-htl @update="update"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 mb-3" v-if="!depositAddress">
            <div class="card-shadow-primary widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  usdt-border card">
                <div class="widget-chat-wrapper-outer d-block">
                    <div class="box-image overflow opacity-3">
                        <img class="rounded-circle image-right card-image" :src="require('@/assets/images/crypto/USDT.png')" alt="HTL" />
                    </div>
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading ">1 HTL =</h6>
                        <div class="widget-numbers w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" >{{htlUsd.toFixed(4)}} USDT</div>
                            </div>
                        </div>
                        <div class="widget-subheading fsize-1 opacity-10 text-warning font-weight-bold">
                            <dialog-usdt @update="update" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 mb-3" v-if="!depositAddress">
            <div class="card-shadow-primary widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  btc-border card">
                <div class="widget-chat-wrapper-outer d-block">

                    <div class="box-image  overflow opacity-3">
                        <img class="rounded-circle image-right card-image" :src="require('@/assets/images/crypto/BTC-logo.png')" alt="HTL" />
                    </div>
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading ">1 HTL =</h6>
                        <div class="widget-numbers w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" >{{htlBtc.toFixed(6)}} BTC</div>
                            </div>
                        </div>
                        <div class="widget-subheading fsize-1 opacity-10 text-warning font-weight-bold">
                            <dialog-btc @update="update"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-6 col-xl-3 mb-3" v-if="!depositAddress">
            <div class="card-shadow-primary widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  ltc-border card">
                <div class="widget-chat-wrapper-outer d-block">
                    <div class="box-image overflow opacity-3">
                        <img class="rounded-circle image-right card-image" :src="require('@/assets/images/crypto/LTC.png')" alt="HTL" />
                    </div>
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading ">1 HTL =</h6>
                        <div class="widget-numbers w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" >{{htlLtc.toFixed(4)}} LTC</div>
                            </div>
                        </div>
                        <div class="widget-subheading fsize-1 opacity-10 text-warning font-weight-bold">
                            <dialog-ltc @update="update" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 mb-3" v-if="depositAddress"></div>
        <div class="col-md-12 col-xl-6 mb-3" v-if="depositAddress">
            <div class="card">
                <div class="card-header text-left text-unset">
                    {{ $t('deposit-tokens', [depositAddress.currency])}}
                </div>
                <div class="card-body d-flex align-center">
                    <div class="widget-content-left w-80">
                        <span>
                            {{ $t('send-tokens', [depositAddress.currency])}}:
                        </span>
                            <br>
                        <span>
                            <b>{{depositAddress.address}}</b>
                        </span>
                    </div>
                    <div class="widget-content-right">

                        <v-btn
                            color="success"
                            v-clipboard="depositAddress.address"
                            class="copy-button float-right"
                            @click="copyAddress"
                        >
                            <font-awesome-icon :icon="['far', 'clone']" small class="fsize-1"/>
                        </v-btn>
                    </div>

                    <v-snackbar
                        v-model="snackbar"
                    >
                        {{ $t('copy-clipboard')}}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                                color="red"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                            >
                                {{ $t('close')}}
                            </v-btn>
                        </template>
                    </v-snackbar>

                </div>
                <div class="card-footer">
                    {{ $t('address-expires', [moment(String(depositAddress.expiresAt)).format('D. MMM YYYY HH:mm')])}}
                    <v-btn color="success"
                           small
                           class="text-unset px-1"
                           dark @click="cancelDeposit">
                        {{ $t('cancel')}}</v-btn>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from "vue";
    import VueClipboards from "vue-clipboards";


    import dialogHtl from '../modals/HtlDepositModal'
    import dialogBtc from '../modals/BtcBuyModal'
    import dialogUsdt from '../modals/UsdtBuyModal'
    import dialogLtc from '../modals/LtcBuyModal'

    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faClone } from '@fortawesome/free-regular-svg-icons'

    library.add(faClone);
    import {mapGetters} from "vuex";
    import gql from "graphql-tag";

    Vue.use(VueClipboards);

    export default {
        components: {
            dialogHtl,
            dialogBtc,
            dialogUsdt,
            dialogLtc,
            "font-awesome-icon": FontAwesomeIcon,
        },
        data: () => ({
            snackbar: false,
        }),
        props: ["depositAddress"],
        computed: {
            ...mapGetters({
                htlEur: 'prices/htlEur',
                htlBtc: 'prices/htlBtc',
                htlUsd: 'prices/htlUsd',
                htlLtc: 'prices/htlLtc'
            })
        },

        methods: {
            copyAddress() {
                this.$copyText(this.depositAddress.address).then(() => this.snackbar = true)
            },
            async cancelDeposit() {
                await this.$apollo.mutate({
                    mutation: gql`mutation($currency:String!){cancelPackageAddress(currency:$currency)}`,
                    variables: {
                        currency: this.depositAddress.currency
                    },
                }).then(() => {
                    this.$emit('update')
                })
            },
            update() {
                this.$emit('update')
            }
        }
    };
</script>

<style scoped>
.copy-button {
    max-width: 32px !important;
    min-width: 30px !important;
}
.box-image {
    float: right;
    width: 20%;
    max-width: 2%;
}

.image-right {
    float: right;
}

.card-image {
    width: 3rem;
}

.overflow {
    overflow: visible;
}
</style>