<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="350"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="text-unset mx-0 htl-btn mb-0"
                    small
                >
                    {{ $t('deposit-htl')}}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2 text-unset">
                    {{ $t('deposit-htl')}}
                    <v-spacer></v-spacer>
                    <v-btn
                        color="transparent"
                        icon
                        @click="dialog = false"
                    >
                        <v-icon color="orange darken-2">clear</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <h3 class="mx-1 d-flex"><div class="w-25"><img src="@/assets/images/crypto/HTL.png" class="currency-logo-main ml-3"></div><div class="w-75">1 HTL</div></h3>
                    <h1 class="mx-1 d-flex "><div class="w-25" /><div class="w-75">=</div> </h1>
                    <h3 class="mx-1 d-flex"><div class="w-25"><img src="@/assets/images/crypto/EUR.png" class="currency-logo-main ml-3"></div><div class="w-75">{{ htlEur.toFixed(4) }} EUR</div></h3>
                    <h3 class="mx-1 d-flex"><div class="w-25"><img src="@/assets/images/crypto/BTC-logo.png" class="currency-logo-main ml-3"></div><div class="w-75">{{ htlBtc.toFixed(6) }} BTC</div></h3>
                    <h3 class="mx-1 d-flex"><div class="w-25"><img src="@/assets/images/crypto/USDT.png" class="currency-logo-main ml-3"></div><div class="w-75">{{ htlUsd.toFixed(4) }} USDT</div></h3>
                    <h3 class="mx-1 d-flex"><div class="w-25"><img src="@/assets/images/crypto/LTC.png" class="currency-logo-main ml-3"></div><div class="w-75">{{ htlLtc.toFixed(4) }} LTC</div></h3>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="success"
                        text
                        @click="getAddress"
                        class="text-unset"
                        small
                    >
                        {{ $t('lock-rate')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import gql from "graphql-tag";

    export default {
        data () {
            return {
                dialog: false,
                lockedRate: null
            }
        },
        computed: {
            ...mapGetters({
                token: 'prices/btcRate',
                htlBtc: 'prices/htlBtc',
                htlEur: 'prices/htlEur',
                htlUsd: 'prices/htlUsd',
                htlLtc: 'prices/htlLtc'
            })
        },
        methods: {
            async getAddress() {
                await this.$apollo.mutate({
                    mutation: gql`mutation($token:String!){requestDeposit(currency:"HTL",token:$token)}`,
                    variables: {
                        token: "1"
                    },
                }).then(() => {
                    this.$emit('update')
                })
            }
        }
    }
</script>

