<template>
    <div class="row mb-3">
        <div class="col-md-12">
            <div class="main-card card">
                <div class="card-header text-left text-unset">
                    {{$t('withdraw-cancel-title')}}
                </div>
                <div class="card-body">
                    <div class="fsize-1">
                        {{$t('withdraw-cancel-body')}}
                    </div>
                </div>
                <div class="card-footer">
                    <v-btn color="primary" class="ma-0 text-unset" @click="cancelWithdrawal" small >{{$t('cancel')}}</v-btn>
                </div>
            </div>
        </div>
        <v-snackbar
            v-model="success"
            :timeout="timeout"
            color="primary"
            absolute
            right
            rounded="pill"
        >
            {{$t('withdraw-cancel-requested')}}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="success = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    props: [
        "limit"
    ],
    data() {
        return {
            address: null,
            success: false,
            amount: 0,
            timeout: 2000,
        }
    },
    mounted() {
        this.amount = this.limit
    },
    methods: {
        async cancelWithdrawal() {
            await this.$apollo.mutate({
                mutation: gql`mutation{cancelWithdrawal}`,
            }).then(() => {
                this.amount = 0
                this.$emit('update')
            })
        }
    }
}

</script>