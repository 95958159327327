<template>
    <div class="mb-3" style="width:100%">
        <div style="width:100%">
            <div class="main-card card">
                <div class="card-header text-left text-unset">

                    Withdraw USDT as HTL to HTL wallet
                </div>
                <div class="card-body">
                    <div class="card-text fsize-1 mb-3">
                        <span style="color:red"> Payment will be made in HTL tokens, you must coppy your HTL wallet address
                            (ERC-20)</span>
                    </div>
                    <div class="card-text fsize-1 mb-3">
                        {{ $t('withdraw-htl-note') }}
                    </div>
                    <div class="spacer" />
                    <div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text pe-7s-wallet" style="line-height: 1.5"></span>
                            </div>
                            <input :placeholder="$t('withdraw-address', ['HTL'])" type="text" class="form-control"
                                v-model="address" />
                        </div>
                        <br />
                        <div class="input-group">
                            <input :placeholder="$t('withdraw-amount')" step="0.01" min="0" type="number"
                                class="form-control" v-model="amount" />
                            <div class="input-group-append">
                                <span class="input-group-text">USDT</span>
                            </div>
                            <!-- <i class="pe-7s-play text-muted fsize-4 " style="margin-top: 3px;"></i>
                            <input :placeholder="$t('withdraw-amount')" step="0.01" type="number" disabled class="form-control" :value="(amount/htlUsd.toFixed(4)/1.05).toFixed(2)" />
                            <div class="input-group-append">
                                <span class="input-group-text">HTL<span style="color:red">*</span></span> 
                            </div> -->

                        </div>
                    </div>
                    <div class="card-text fsize-1 mb-3" style="align:right ;">
                        <span style="color:red"> *The real value will be determined at the time of withdraw
                            confirmation</span>
                    </div>

                    <div class="mt-1">
                        <v-btn color="primary" class="ma-0 text-unset" @click="requestWithdrawal" :disabled="!kyc" small>
                            {{ $t('withdraw-request') }}
                        </v-btn>
                        <br>
                        <span class="red--text" v-if="!kyc">{{ $t('withdraw-no-kyc') }}</span>
                        <span class="red--text" v-if="missingAddress === true">Missing HTL address!</span>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="success" :timeout="timeout" color="primary" absolute right rounded="pill">
            {{ $t('withdraw-requested', [lastAmount, 'HTL']) }}

            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="success = false" class="text-unset">
                    {{ $t('close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
    props: [
        "limit",
        "kyc",
        "maxAmount"
    ],
    data() {
        return {
            address: null,
            success: false,
            amount: 0,
            timeout: 2000,
            lastAmount: 0,
            missingAddress: false
        }
    },
    computed: {
        ...mapGetters({
            token: 'prices/usdRate',
            htlBtc: 'prices/htlBtc',
            htlEur: 'prices/htlEur',
            htlUsd: 'prices/htlUsd',
            htlLtc: 'prices/htlLtc'
        })
    },
    mounted() {
        //Math.max, da ne pride do vnosa negativnega zneska
        //Math.min, da nastavi stanje na racunu, ce je to manjse od limite oziroma, da nastavi  limito, ce imamo visje stanje na racunu
        this.amount = Math.max(Math.min(Math.floor(this.limit * this.htlUsd * 100) / 100, this.maxAmount / 100000000), 0);
    },
    methods: {
        async requestWithdrawal() {
            if (this.address === null) {
                this.missingAddress = true;
                return;
            }
            else {
                this.missingAddress = false;
            }

            await this.$apollo.mutate({
                mutation: gql`mutation($address:String!,$amount:Float!,$rate:Float!){requestWithdrawal(amount:$amount,address:$address,currency:"HTL",currencyFrom:"USDT", rate:$rate)}`,
                variables: {
                    amount: parseFloat(this.amount),
                    address: this.address,
                    rate: this.htlUsd
                },

            }).then(() => {
                this.$router.go(0)
            })
        }
    }
}

</script>