<template>
  <div class="" style="width:100%">
    <div style="width:100%">
      <div class="main-card card">
        <div class="card-header text-left text-unset">
          Hotelium Exchange
        </div>
        <div class="card-body">
          <div class="card-text fsize-1 mb-3">
            You can convert USDT into HTL here.
          </div>
          <div class="card-text fsize-1 mb-3">
            Exchange fee is 1%.
          </div>
          <div class="spacer" />
          <div>
            <div class="input-group">
              <input :placeholder="$t('withdraw-amount')" step="0.01" type="number" class="form-control"
                v-model="amount" />
              <div class="input-group-append">
                <span class="input-group-text">USDT</span>
              </div>
              <i class="pe-7s-play text-muted fsize-4 " style="margin-top: 3px;"></i>
              <input :placeholder="$t('withdraw-amount')" step="0.01" type="number" min="0" disabled class="form-control"
                :value="((amount * 0.99) / htlUsd.toFixed(4)).toFixed(2)" />
              <div class="input-group-append">
                <span class="input-group-text">HTL</span>
              </div>

            </div>

            <div class="mt-2">
              <v-btn color="primary" class="text-unset mx-0" @click="requestExchange" :disabled="amount === 0" small>
                Exchange USDT to HTL
              </v-btn>
              <br />

            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="success" :timeout="timeout" color="primary" absolute right rounded="pill">
      Exchanged {{ lastAmount }} USDT

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="success = false" class="text-unset">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
export default {
  props: ["maxAmount"],
  computed: {
    ...mapGetters({
      htlUsd: 'prices/htlUsd'
    })
  },
  data() {
    return {
      success: false,
      amount: 0,
      timeout: 2000,
      lastAmount: 0,
    };
  },
  mounted() {
    this.amount = Math.max((this.maxAmount / 100000000).toFixed(2), 0);
  },
  methods: {
    async requestExchange() {

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation ($from: Int!, $to: Int!, $amount: Float!, $rate:Float!) {
              exchange(
                from: $from,
                to:$to,
                amount: $amount,
                rate:$rate
              )
            }
          `,
          variables: {
            from: 1,
            to: 0,
            amount: parseFloat(this.amount),
            rate: this.htlUsd
          },
        })
        .then(() => {
          this.lastAmount = this.amount;
          this.amount = 0;
          this.$emit("update");
        });
    },
  },
};
</script>